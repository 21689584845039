<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Pengaturan Finger ID</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units"
                v-model="unit"
                :loading="loadingUnit"
                placeholder="Unit Utama"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                clearable
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units2"
                v-model="unit2"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                clearable
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units3"
                v-model="unit3"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
                @change="changeUnit(3)"
                @click:clear="changeUnit(3)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units4"
                v-model="unit4"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                clearable
              ></v-autocomplete>
            </v-col> -->
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-text-field
                placeholder="Cari"
                v-model="search"
                outlined
                dense
                hide-details
                clearable
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="12" class="px-0">
              <v-card-actions>
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="handleAdd"
                  >Buat Finger ID<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="machines"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            hide-default-header
          >
            <template #header>
              <thead>
                <tr id="border-btm">
                  <th colspan="4" class="text-center">Data Pegawai</th>
                  <th colspan="3" class="text-center">Data Finger</th>
                  <th rowspan="2" class="text-center">Action</th>
                </tr>
                <tr>
                  <th class="text-center">NIP</th>
                  <th class="text-center">Nama</th>
                  <th class="text-center">Unit Kerja</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Kode Device</th>
                  <th class="text-center">Unit Kerja</th>
                  <th class="text-center">Status</th>
                </tr>
              </thead>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
              <span
                :class="
                  item.is_active == 1 ? 'success-color' : 'subtitle-color'
                "
                >{{ item.is_active_name }}</span
              >
            </template>
            <template v-slot:[`item.unit_kerja`]="{ item }">
              <div class="py-1" style="min-width: 150px">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.unit_utama_name }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 2: </b>{{ item.unit_kerja_2_name }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.unit_kerja_finger`]="{ item }">
              <div class="py-1" style="min-width: 150px">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.finger_unit_utama_name }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja 2: </b>{{ item.finger_unit_kerja_2_name }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
              >
                {{ item.id ? "Ubah" : "Buat" }}
              </v-btn>
              <v-btn
                v-if="item.id"
                x-small
                color="#FBB005"
                class="mr-2 my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="700">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          {{ form.id ? "Ubah" : "Tambah" }} Finger ID
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama/NIP</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-text-field
                id="employee-input-form"
                v-model="formInput"
                placeholder="Pilih Nama Pegawai"
                outlined
                dense
                :disabled="isEdit"
                readonly
                @click="$refs.employeeSearch.change(true)"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-select
                placeholder="- Semua Unit Utama -"
                v-model="form.unit_utama_id"
                :items="units"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnitForm(1)"
                @click:clear="changeUnitForm(1)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-select
                placeholder="- Semua Unit Kerja -"
                v-model="form.unit_kerja_2_id"
                :items="units2Form"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kode Device</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-text-field
                v-model="form.kode_device"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Aktif</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-select
                :items="status"
                v-model="form.is_active"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <EmployeeSearchDialog
      ref="employeeSearch"
      :lists="[form]"
      value="NIP"
      @handle-pick="pickItem"
      singlePick
    />
  </div>
</template>
<script>
import MachineService from "@/services/resources/machine.service";
import PublicService from "@/services/resources/public.service";
import DataUtamaService from "@/services/resources/data.utama.service";

const EmployeeSearchDialog = () => import("@/components/Dialog/EmployeeSearch");

export default {
  components: {
    EmployeeSearchDialog
  },
  data() {
    return {
      visible: false,
      search: null,
      valid: false,
      isEdit: false,
      loadingEmployee: false,
      loadingUnit: false,
      formLoading: false,
      status: [
        { text: "Ya", value: 1 },
        { text: "Tidak", value: 0 }
      ],
      unit: null,
      unit2: null,
      unit3: null,
      unit4: null,
      units: [],
      units2: [],
      units3: [],
      units4: [],
      units2Form: [],
      employees: [],
      headers: [
        { text: "NIP", value: "NIP", sortable: false, align: "left" },
        { text: "Nama", value: "nama", sortable: false, align: "left" },
        {
          text: "Unit Kerja",
          value: "unit_kerja",
          sortable: false,
          align: "left"
        },
        {
          text: "Status",
          value: "status_aktif",
          sortable: false,
          align: "left"
        },
        {
          text: "Kode Device",
          value: "kode_device",
          sortable: false,
          align: "left"
        },
        {
          text: "Unit Kerja",
          value: "unit_kerja_finger",
          sortable: false,
          align: "left"
        },
        { text: "Aktif", value: "is_active", sortable: false, align: "left" },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "left"
        }
      ],
      form: {
        id: null,
        NIP: null,
        name: null,
        kode_device: null,
        unit_kerja_2_id: null,
        is_active: 1
      },
      totalItem: 0,
      pageCount: 0,
      loading: true,
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      machines: []
    };
  },
  computed: {
    paginationProperty() {
      return [this.search, this.unit, this.unit2].join();
    },
    formInput() {
      if (this.form.NIP == null) return;
      return `${this.form.NIP} - ${this.form.name}`;
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListDataNIP);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListDataNIP);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
        this.form.id = null;
        this.form.NIP = null;
        this.form.name = null;
      }
    }
  },
  methods: {
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    changeUnitForm(type = 1) {
      if (type == 1) {
        this.units2Form = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    populateUnit() {
      this.units2Form = this.units.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja2;
    },
    handleAdd() {
      this.visible = true;
      this.isEdit = false;
    },
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      const id = item.id || 0;
      this.getDetailDataNIP(`${id}/${item.NIP}`);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteDataNIP({
              id: item.id
            });
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          action: this.form.id && this.form.id != "0" ? 2 : 1,
          id: this.form.id,
          NIP: this.form.NIP,
          kode_device: this.form.kode_device,
          unit_utama_id: this.form.unit_utama_id,
          unit_kerja_2_id: this.form.unit_kerja_2_id,
          is_active: this.form.is_active
        };
        this.saveDataNIP(payload);
      }
    },
    pickItem(value) {
      this.form.NIP = value.NIP;
      this.form.name = value.nama;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListEmployee() {
      try {
        this.loadingEmployee = true;
        await PublicService.getUserSearchList({
          filter: {
            search: this.form.NIP
          },
          orderBy: ["NIP", "asc"],
          pageCurrent: 1,
          dataPerPage: 10
        })
          .then(response => {
            const { status, data } = response.data;
            const { list } = data;
            if (status) {
              this.employees = list;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingEmployee = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailDataNIP(id) {
      try {
        this.loadingEmployee = true;
        await MachineService.getDetailDataNIP(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form.id = data.id;
              this.form.NIP = data.NIP;
              this.form.name = data.nama;
              this.form.kode_device = data.kode_device;
              this.form.unit_utama_id = data.unit_utama_id;
              this.form.unit_kerja_2_id = data.unit_kerja_2_id;
              this.form.is_active = data.is_active;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingEmployee = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveDataNIP(payload) {
      try {
        this.formLoading = true;
        await MachineService.saveDataNIP(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.visible = false;
              this.getListDataNIP();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListDataNIP() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await MachineService.getListDataNIP({
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let machines = list;
              machines.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.machines = machines;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteDataNIP(payload) {
      try {
        this.formLoading = true;
        await MachineService.deleteDataNIP(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListDataNIP();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#border-btm > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
